import axios from "axios";

export const base_url = "http://staging.paronline.in:8080/";

export const api_call = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: { "Content-Type": "application/json" },
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

const fetchClient = () => {
  let instance = axios.create({
    baseURL: base_url,
    timeout: 300000,
    headers: { "Content-Type": "application/json" },
    validateStatus: (status) => status < 500,
    transformRequest: [
      function (data, headers) {
        return JSON.stringify(data);
      },
    ],
  });
  
  instance.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem("phoolchand-access-token"));
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });
  instance.interceptors.response.use((res) => {
    if (res.status === 401) {
      // localStorage.removeItem("ed_user");
      localStorage.removeItem("phoolchand-access-token");
      window.location.reload();
    }
    return res;
  });
  return instance;
};

export const api_call_token = fetchClient();

const fetchClients = () => {
  let instance = axios.create({
    baseURL: base_url,
    timeout: 300000,
    headers: { "Content-Type": "application/json" },
    validateStatus: (status) => status < 500,
    transformRequest: [
      function (data, headers) {
        return JSON.stringify(data);
      },
    ],
  });
  instance.interceptors.request.use(function (config) {
    const token = JSON.parse(localStorage.getItem("phoolchand-access-token"));
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });
  instance.interceptors.response.use((res) => {
    if (res.status === 401) {
      localStorage.removeItem("phoolchand-access-token");
      window.location.reload();
    }
    return res;
  });
  return instance;
};

export const api_call_token_user = fetchClients();

export async function formDataApi(
  apiName = "",
  formData,
  patch = false,
  apiUrl = "container"
) {
  let data = {};
  const token = JSON.parse(localStorage.getItem("phoolchand-access-token"));
  if (patch)
    await axios
      .patch(base_url + apiUrl + apiName, formData, {
        // headers: { "content-type": "multipart/form-data" },
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "content-type": "multipart/form-data",
        },
        validateStatus: (status) => status < 500,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return (data = response.data);
        return (data = response.data);
      })
      .catch((e) => console.log(e));
  else
    await axios
      .post(base_url + apiUrl + apiName, formData, {
        // headers: { "content-type": "multipart/form-data" },
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "content-type": "multipart/form-data",
        },
        validateStatus: (status) => status < 500,
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201)
          return (data = response);
        return (data = response);
      })
      .catch((e) => console.log(e));
  return data;
}
